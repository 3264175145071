<template>
  <div class="wengao container">
    <Tips />
    <div class="article">
      <video
        :poster="videoPoster"
        :src="videoSrc"
        style="width: 100%; height: auto"
        controls
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        x5-playsinline
        width="100%"
      ></video>
      <div class="article-title">{{ article.title }}</div>
      <ul class="article-info">
        <li class="source" v-if="article.source">{{ article.source }}</li>
        <li class="time">{{ article.time }}</li>
        <li>
          浏览：
          <span class="readNum">{{ article.readNum }}</span>
        </li>
      </ul>
      <div class="article-content animated" ref="article">
        <p v-html="article.content">{{ article.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Article from "@/components/article";
  import { getPreviewDate } from "@/utils/api";
  import { wxShare } from "@/utils/wxShare";
  import Tips from "@/components/tips";
  export default {
    name: "previewshipin",
    components: {
      Article,
      Tips,
    },
    data() {
      return {
        canShow: false,
        id: "",
        path: "kczVideoDetail",
        commentList: [],
        article: {
          title: "",
          source: "",
          time: "",
          readNum: "",
        },
        videoSrc: "",
        videoPoster: "",
        share_config: {},
      };
    },
    methods: {
      async getPreviewDate(id) {
        const res = await getPreviewDate(id);
        const data = res.data.data;
        this.article.title = data.title;
        this.article.source = data.source_from;
        this.article.time = data.published_at;
        this.article.readNum = data.views;
        this.article.can_comment = data.can_comment;
        if (data.materials.video) {
          this.videoPoster = data.materials.video.cover;
          this.videoSrc = data.materials.video.src;
        } else {
          this.videoPoster = data.materials.minivideo.cover;
          this.videoSrc = data.materials.minivideo.src;
        }
        this.share_config = data.share_config;
        this.canShow = true;
        document.title = "预览-" + data.title;
        //wxShare(this.share_config.title, this.share_config.share_img, window.location.href, this.share_config.summary);
        wxShare("快艺学APP", "https://sharekcz.cztv.tv/cbs.png", "https://sharekcz.cztv.tv/index", "常州，教我如何不想她");
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getPreviewDate(this.id);
    },
  };
</script>

<style lang="scss"></style>
