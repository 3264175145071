<template>
  <div class="article">
    <div class="article-title" ref="articleTitle">{{ article.title }}</div>
    <ul class="article-info" ref="articleInfo">
      <li class="time">{{ article.time }}</li>
      <li>
        浏览：
        <span class="readNum">{{ article.readNum }}</span>
      </li>
    </ul>
    <div class="article-content animated" :class="arrowShow ? 'vague' : ''" ref="article" :style="{ height: height }">
      <audio :src="audioSrc" v-if="audioSrc.length > 0" controls style="margin: 10px auto; display: block; width: 92%"></audio>
      <p v-html="article.content">{{ article.content }}</p>
      <img src="@/assets/images/arrow.png" class="arrow shake" v-if="arrowShow" @click="handleArrow" />
    </div>
    <div class="article-info source-info">
      <div class="source" v-if="article.source">{{ article.source }}</div>
      <div class="author" v-if="article.author">{{ article.author }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Article",
    props: {
      article: {
        type: Object,
        default() {
          return {
            title: "",
            source: "",
            author: "",
            time: "",
            readNum: "",
            content: "",
          };
        },
      },
      audioSrc: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        arrowShow: false,
        height: "auto",
      };
    },
    methods: {
      getHeight() {
        let allHeight = document.documentElement.clientHeight;
        let height = this.$refs.article.offsetHeight;
        let iHeight = this.$refs.articleInfo.offsetHeight;
        let tHeight = this.$refs.articleTitle.offsetHeight;
        let oHeight = this.$refs.articleTitle.offsetTop;
        let vegue = allHeight - iHeight - tHeight - oHeight;
        if (height > vegue) {
          this.arrowShow = true;
          this.height = vegue + 100 + "px";
        } else {
          this.arrowShow = false;
        }
      },
      handleArrow() {
        this.height = "auto";
        this.arrowShow = false;
      },
    },
    mounted() {
      this.getHeight();
    },
  };
</script>

<style lang="scss">
  .shake {
    animation: shake 0.6s linear alternate infinite;
    z-index: 99;
  }
  @keyframes shake {
    0% {
      transform: translateY(-6px);
    }
    100% {
      transform: translateY(6px);
    }
  }
  .article-info {
    .source,
    .author {
      font-size: 12px;
    }
  }
  .source-info {
    margin-bottom: 30px;
  }
</style>
